import React, { useState, memo, Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Layout, Menu, Icon, Typography, Row, Col, Avatar, Button } from 'antd'
import { ReactComponent as LogoIcon } from '@static/logo.svg'

function initNetlifyIdentity() {
  console.log("initNetlifyIdentity called.")
  const script = document.createElement("script");

  script.src = "https://identity.netlify.com/v1/netlify-identity-widget.js"
  script.async = true;

  document.body.appendChild(script);
}

function openNetlifyModal() {
  const netlifyIdentity = window.netlifyIdentity;

  if(netlifyIdentity)
    netlifyIdentity.open();
  else
    console.log('netlifyIdentity not defined')
}

class NetlifyIdentity extends Component {
  componentDidMount() {
    initNetlifyIdentity();
  }

  render() {
    return(<div></div>)
  }
}

const LandingLayout = ({ children }) => {
  const [collapsed, toggle] = useState(true)
  return (
    <div className='landing-layout'>
      <Layout>
        <Layout.Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          theme='light'
        >
          <div className='h-20 m-2 flex items-center justify-around'>
            <LogoIcon className='h-10 w-10 mr-2 fill-primary-color' />
            <Typography.Title
              level={4}
              className={classNames('text-primary-color', {
                hidden: collapsed,
              })}
            >
              {process.env.APP_NAME}
            </Typography.Title>
          </div>
          <Menu
            theme='light'
            mode='inline'
            defaultSelectedKeys={['1']}
            className='border-0'
          >
            <Menu.Item key='1'>
              <Icon type='dashboard' />
              <span>Dashboard</span>
            </Menu.Item>
            <Menu.Item key='2'>
              <Icon type='search' />
              <span>Search</span>
            </Menu.Item>
            <Menu.Item key='3'>
              <Icon type='container' />
              <span>Orders</span>
            </Menu.Item>
            <Menu.Item key='4'>
              <Icon type='tags' />
              <span>Products</span>
            </Menu.Item>
            <Menu.Item key='5'>
              <Icon type='shop' />
              <span>Vendors</span>
            </Menu.Item>
            <Menu.Item key='6'>
              <Icon type='idcard' />
              <span>Accounts</span>
            </Menu.Item>
            <Menu.Item key='7'>
              <Icon type='solution' />
              <span>Reports</span>
            </Menu.Item>
            <Menu.Item key='8'>
              <Icon type='setting' />
              <span>Settings</span>
            </Menu.Item>
          </Menu>
        </Layout.Sider>
        <Layout>
          <NetlifyIdentity />
          <Layout.Header style={{ background: '#fff', padding: 0 }}>
            <Row>
              <Col span={8}>
                <Icon
                  className='leading-normal text-base'
                  type={collapsed ? 'menu-unfold' : 'menu-fold'}
                  onClick={() => toggle(!collapsed)}
                />
              </Col>
              <Col span={4} offset={12}>
                <Avatar style={{ verticalAlign: 'middle' }} size="large">
                  {/*{user}*/}
                </Avatar>
                <Button
                  size="small"
                  style={{ margin: '0 16px', verticalAlign: 'middle' }}
                  onClick={openNetlifyModal}
                >
                  Sign In
                </Button>
              </Col>
            </Row>
          </Layout.Header>
          <Layout.Content
            style={{
              margin: '24px 16px',
              padding: 24,
              background: '#fff',
              minHeight: 280,
            }}
          >
            <div style={{ minHeight: '100vh' }}>{children}</div>
          </Layout.Content>
        </Layout>
      </Layout>
    </div>
  )
}

LandingLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default memo(LandingLayout)
